import React from 'react';

import { clsx, slugify } from '@digital-spiders/misc-utils';
import { openFormModal } from '../../stores/formModalStore';
import { openTermsModal } from '../../stores/termsModalOpenStore';
import type { VersatileLink } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import Button, { type ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import type { FormField, FormFieldWithId } from './Form';
import SmartLink from './SmartLink';
import TextLink from './TextLink';

export interface ButtonLinkProps extends ButtonProps {
  to: VersatileLink;
  linkClassName?: string;
}

const ButtonLink = ({ to, linkClassName, ...restProps }: ButtonLinkProps): React.ReactElement => {
  const additionalFormFields = [
    {
      crmFieldId: 'acceptTheTerms',
      fieldType: 'singleCheckbox',
      isFieldRequired: true,
      textNode: (
        <p>
          I agree to the{' '}
          <TextLink
            styleOnly
            onClick={e => {
              e.preventDefault();
              openTermsModal();
            }}
          >
            terms and conditions
          </TextLink>{' '}
        </p>
      ),
      labelAsText: 'I agree to the terms and conditions',
    },
    {
      crmFieldId: 'joinTheHookPointNewsletter',
      fieldType: 'singleCheckbox',
      isFieldRequired: false,
      textNode: <p>Join The Going Viral Newsletter!</p>,
      labelAsText: 'Join The Going Viral Newsletter!',
    },
  ] as Array<FormField>;

  const formFieldsWithIds: Array<FormFieldWithId> = to.downloadFileFormModal
    ? [...to.downloadFileFormModal.formFields, ...additionalFormFields].map(formField => ({
        id: slugify(
          formField.fieldType === 'singleCheckbox' ? formField.labelAsText : formField.title,
        ),
        ...formField,
      }))
    : [];

  if (to.downloadFileFormModal && formFieldsWithIds.length > 1) {
    return (
      <Button
        {...restProps}
        className={clsx(restProps.className)}
        onClick={() =>
          openFormModal({
            formModal: to.downloadFileFormModal,
            formFieldsWithIds,
          })
        }
      ></Button>
    );
  } else {
    return (
      <SmartLink
        className={clsx(styles.link, linkClassName)}
        to={getUrlFromVersatileLink(to)}
        tabIndex={-1}
      >
        <Button {...restProps}></Button>
      </SmartLink>
    );
  }
};

export default ButtonLink;
